import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { useIntl } from 'gatsby-plugin-intl'

const useStyles = (isTitleHanging = false) =>
makeStyles((theme) => ({
  root: {
    fontFamily: `'Cormorant Garamond', serif`,
    textAlign: 'center',
    width: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.between('xs', 'md')]: {
      minHeight: '60vh',
    },
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(6),
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '3rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    lineHeight: '.9',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      fontSize: theme.typography.pxToRem(20),
    },
  },
  title2: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1.5rem',
    lineHeight: 1.1,
    textAlign: 'left',
    fontWeight: 400,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      fontSize: theme.typography.pxToRem(17),
    },
  },
  enum1: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1rem',
    lineHeight: .9,
    textAlign: 'left',
    marginTop: theme.spacing(.5),
    marginBottom: theme.spacing(.1),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  enum2: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1rem',
    lineHeight: .9,
    textAlign: 'left',
    marginTop: theme.spacing(.5),
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(4),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  description: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1rem',
    lineHeight: 1.1,
    textAlign: 'left',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(.8),
      fontSize: theme.typography.pxToRem(12),
    },
  },
  descriptionBold: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1.1rem',
    lineHeight: 1.1,
    textAlign: 'left',
    fontWeight: 600,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.6),
      marginBottom: theme.spacing(.9),
      fontSize: theme.typography.pxToRem(14),
    },
  },
  address: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1rem',
    lineHeight: 1.2,
    textAlign: 'left',
    marginTop: theme.spacing(.6),
    marginBottom: theme.spacing(.4),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  addressBloc: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

export const LegalNotice: React.FC<any> = () => {
  const intl = useIntl()
  const classes = useStyles(false)()
  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid item md={9} sm={10}>
        <Grid item sm={12} className={classes.title}>
          {intl.formatMessage({ id: 'link.legalNotice' })}
        </Grid>
        <Grid item sm={12} className={classes.title2}>
          {intl.formatMessage({ id: 'legalNotice.title_art_1' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalNotice.description_art_1' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalNotice.coord' })}
        </Grid>
        <Grid item sm={12} className={classes.addressBloc}>
          <Grid item sm={12} className={classes.address}>
            {intl.formatMessage({ id: 'legalNotice.name' })}
          </Grid>
          <Grid item sm={12} className={classes.address}>
            {intl.formatMessage({ id: 'legalNotice.address' })}
          </Grid>
          <Grid item sm={12} className={classes.address}>
            {intl.formatMessage({ id: 'legalNotice.tel' })}
          </Grid>
          <Grid item sm={12} className={classes.address}>
            {intl.formatMessage({ id: 'legalNotice.email' })}
          </Grid>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalNotice.pubDir' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalNotice.host' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalNotice.user' })}
        </Grid>
        <Grid item sm={12} className={classes.title2}>
          {intl.formatMessage({ id: 'legalNotice.title_art_2' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>2.1</span><span>{' - ' + intl.formatMessage({ id: 'legalNotice.description_art_2_1' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>2.2</span><span>{' - ' + intl.formatMessage({ id: 'legalNotice.description_art_2_2' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>2.3</span><span>{' - ' + intl.formatMessage({ id: 'legalNotice.description_art_2_3' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>2.4</span><span>{' - ' + intl.formatMessage({ id: 'legalNotice.description_art_2_4' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.title2}>
          {intl.formatMessage({ id: 'legalNotice.title_art_3' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalNotice.description_art_3' })}
        </Grid>
        <Grid item sm={12} className={classes.title2}>
          {intl.formatMessage({ id: 'legalNotice.title_art_4' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>4.1</span><span>{' - ' + intl.formatMessage({ id: 'legalNotice.description_art_4_1' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>4.2</span><span>{' - ' + intl.formatMessage({ id: 'legalNotice.description_art_4_2' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>4.3</span><span>{' - ' + intl.formatMessage({ id: 'legalNotice.description_art_4_3' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          <span style={{fontWeight: 'bold'}}>4.4</span><span>{' - ' + intl.formatMessage({ id: 'legalNotice.description_art_4_4' })}</span>
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalNotice.outro_art_4' })}
        </Grid>
        <Grid item sm={12} className={classes.title2}>
          {intl.formatMessage({ id: 'legalNotice.title_art_5' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalNotice.description_art_5' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalNotice.update' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalNotice.applicable' })}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LegalNotice
